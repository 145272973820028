<template>
  <div class="suppliers-map" ref="map"></div>
</template>

<script>
import mapStyle from 'apps/public/lib/google-maps-style.json';
import pinMarker from 'apps/public/img/pin-marker.svg';

export default {
  props: {
    supplier: {
      default: {},
    },
  },
  data() {
    return {
      svg: {
        pinMarker,
      },
    };
  },
  computed: {
    coordinates() {
      if (this.supplier.coordinates) {
        return {
          latitude: this.supplier.coordinates.split(', ')[0],
          longitude: this.supplier.coordinates.split(', ')[1],
        };
      } else {
        return null;
      }
    },
  },
  methods: {
    // Google Maps
    async initMap() {
      if (!this.coordinates) return;

      await this.$store.countries.loadGMaps();

      // Map position
      const mapPosition = new google.maps.LatLng(this.coordinates.latitude, this.coordinates.longitude);

      // Main options
      const options = {
        center: mapPosition,
        zoom: 5,
        styles: mapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        backgroundColor: '#e3eaf3',
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: true,
      };

      // Init map
      const map = new google.maps.Map(this.$refs.map, options);

      // Marker
      const markerIcon = () => {
        let icon = window.btoa(this.svg.pinMarker);
        return {
          url: `data:image/svg+xml;base64,${icon}`,
          scaledSize: new google.maps.Size(30, 30),
          anchor: new google.maps.Point(15, 30),
        };
      };
      const marker = new google.maps.Marker({
        position: mapPosition,
        map: map,
        icon: markerIcon(),
      });

      this.$emit('init');
    },
  },
  mounted() {
    this.initMap();
  },
  watch: {
    '$route.fullPath'() {
      setTimeout(() => {
        this.initMap();
      }, 300);
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.suppliers-map {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  button img {
    display: inline-block;
  }
}
</style>
